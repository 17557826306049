import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import { Tooltip } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Paper from '@mui/material/Paper'
import { styled, useTheme } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import useMediaQuery from '@mui/material/useMediaQuery'
import html2pdf from 'html2pdf.js'
import React, { useRef } from 'react'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F58220',
    color: theme.palette.common.white,
    padding: '3px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: '3px',
    textAlign: 'left',
  },
}))
const StyleTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F58220',
    color: theme.palette.common.white,
    padding: '10px',
    fontWeight: 800,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: '10px',
    textAlign: 'left',
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const ReadmeFile = ({ open, onClose }) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const pdfRef = useRef(null)

  const handleDownload = () => {
    const element = pdfRef.current
    const opt = {
      margin: 1,
      filename: 'readme-file.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    }
    html2pdf().from(element).set(opt).save()
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        fullScreen={fullScreen}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          id="responsive-dialog-title"
          sx={{ display: 'flex', justifyContent: 'flex-end', padding: 0 }}
        >
        </DialogTitle>
        <DialogContent>
          <div
            style={{ display: 'flex', justifyContent: 'flex-end', padding: 0 }}
          >
            <Tooltip title={'Download PDF'} arrow>
              <Button
                sx={{
                  marginTop: '20px',
                  padding: 0,
                  cursor: 'pointer',
                }}
                onClick={handleDownload}
              >
                <CloudDownloadIcon />
              </Button>
            </Tooltip>
          </div>
          <div ref={pdfRef}>
            <DialogContentText sx={{ marginTop: '-10px' }}>
              <div style={{ marginBottom: '20px' }}>
                <h3>Risk Management Guide</h3>
                Refer to the below steps to document and manage your
                account/project risks
              </div>
              <TableContainer component={Paper} sx={{ marginBottom: '20px' }}>
                <Table
                  sx={{ minWidth: 500, marginBottom: '10px' }}
                  aria-label="customized table"
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell colSpan={3} sx={{ color: 'orange' }}>
                        Step I: Risk Metadata
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        Risk ID
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        Provide a suitable ID or Serial number (Optional)
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        Vertical
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        Name of Vertical
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        Account
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        Name of Account Name
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        Project
                      </StyledTableCell>{' '}
                      <StyledTableCell align="right">
                        Name of Projects
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        Workstream
                      </StyledTableCell>{' '}
                      <StyledTableCell align="right">
                        Name of Workstream
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <TableContainer component={Paper} sx={{ marginBottom: '20px' }}>
                <Table sx={{ minWidth: 500 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell colSpan={3} sx={{ color: 'orange' }}>
                        Step II: Risk Identification
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        Category
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        Appropriate category of the risk (Ex: People risk,
                        Technology Risk etc.)
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        Description of Cause or Event (Risks)
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        State the risk or briefly describe it
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        Consequence(s)
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        Describe the potential impact that the risk may create
                        if not treated
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <TableContainer component={Paper} sx={{ marginBottom: '20px' }}>
                <Table sx={{ minWidth: 500 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell colSpan={3} sx={{ color: 'orange' }}>
                        Step III: Risk Analysis
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        Existing Controls or Measures
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        Description of any existing controls or measures for
                        alleviating the risk
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        Likelihood
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <b>High</b>: Event is expected to occur in most
                        circumstances. If the event occurs very frequently.{' '}
                        <br />
                        <b>Medium</b>: Event could occur in rare circumstances.
                        If the event occurs only occasionally. <br /> <b>Low</b>
                        : Event may occur only in exceptional circumstances. If
                        the event occurs very rarely.
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        Impact
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <b>High</b>
                        <br /> - Results in serious damage and affect Tredence’s
                        ability to continue its business.
                        <br /> - Expected to have a significant impact on
                        Tredence’s reputation. <br />- May lead to extended
                        disruption and inconvenience to interested parties
                        involved.
                        <br /> <b>Medium</b>
                        <br /> - Likely to have some short-term impact or
                        setback that could lead to disruption and require
                        escalations.
                        <br /> - May result in moderate penalties / costs that
                        cannot be borne via normal operational budgets.
                        <br />
                        <b>Low</b> <br />- Unlikely to have an impact and could
                        be dealt with normal operating procedures
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        Risk Level
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        Overall Risk Level depending on Impact/Likelihood using
                        the below matrix:
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <TableContainer
                component={Paper}
                sx={{
                  marginBottom: '20px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Table sx={{ minWidth: 100 }} aria-label="customized table">
                  <TableBody>
                    <StyledTableRow>
                      <StyleTableCell
                        component="th"
                        scope="row"
                        sx={{
                          backgroundColor: '#1a0f61',
                          color: '#fff',
                          padding: '20px',
                        }}
                      >
                        Impact
                      </StyleTableCell>

                      <StyleTableCell align="right" rowSpan={2}>
                        High
                      </StyleTableCell>
                      <StyleTableCell align="right" rowSpan={2}>
                        Medium
                      </StyleTableCell>
                      <StyleTableCell align="right" rowSpan={2}>
                        Low
                      </StyleTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyleTableCell
                        component="th"
                        scope="row"
                        sx={{ backgroundColor: '#9b9b9f', color: '#000' }}
                      >
                        Likelihood
                      </StyleTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyleTableCell
                        component="th"
                        scope="row"
                        sx={{ padding: '20px', fontWeight: 800 }}
                      >
                        High
                      </StyleTableCell>
                      <StyleTableCell
                        align="right"
                        sx={{ background: 'red', padding: '20px' }}
                      >
                        Critical
                      </StyleTableCell>
                      <StyleTableCell
                        align="right"
                        sx={{ background: '#edb13c' }}
                      >
                        High
                      </StyleTableCell>
                      <StyleTableCell
                        align="right"
                        sx={{ background: '#f2fb21' }}
                      >
                        Medium
                      </StyleTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyleTableCell
                        component="th"
                        scope="row"
                        sx={{ fontWeight: 800 }}
                      >
                        Medium
                      </StyleTableCell>

                      <StyleTableCell
                        align="right"
                        sx={{ background: '#edb13c' }}
                      >
                        High
                      </StyleTableCell>
                      <StyleTableCell
                        align="right"
                        sx={{ background: '#f2fb21' }}
                      >
                        Medium
                      </StyleTableCell>
                      <StyleTableCell
                        align="right"
                        sx={{ background: '#96bd49' }}
                      >
                        Low
                      </StyleTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyleTableCell
                        component="th"
                        scope="row"
                        sx={{ fontWeight: 800 }}
                      >
                        Low
                      </StyleTableCell>

                      <StyleTableCell
                        align="right"
                        sx={{ background: '#f2fb21' }}
                      >
                        Medium
                      </StyleTableCell>
                      <StyleTableCell
                        align="right"
                        sx={{ background: '#96bd49' }}
                      >
                        Low
                      </StyleTableCell>
                      <StyleTableCell
                        align="right"
                        sx={{ background: '#00A86B' }}
                      >
                        Very Low
                      </StyleTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <TableContainer component={Paper} sx={{ marginBottom: '20px' }}>
                <Table
                  sx={{ minWidth: 500, marginBottom: '10px' }}
                  aria-label="customized table"
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell colSpan={3} sx={{ color: 'orange' }}>
                        Step IV: Risk Response
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        Risk Response
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <b>Accept</b>: A low rated risk with low likelihood and
                        impact is accepted without any further treatment under
                        this option. <br /> <b>Reduce</b>: Further actions are
                        required to bring down the Risk rating further down to
                        acceptable levels. <br />
                        <b>Transfer</b>: The risks and their consequences to be
                        transferred to another party. <br /> <b>Avoid</b>: The
                        risk to be avoided, by avoiding/eliminating the exposure
                        to the identified threat/risks.
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        Risk Response details
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        Mitigation detail or description of the decided
                        response. You can also provide your remarks here when
                        reviewing the risks
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        Risk Owner
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        Owner responsible for tracking and treatment of the
                        risk. This can either be a name of person or a role in
                        the team
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        Status
                      </StyledTableCell>{' '}
                      <StyledTableCell align="right">
                        Current status of the risk (Open, Closed)
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        ETA
                      </StyledTableCell>{' '}
                      <StyledTableCell align="right">
                        Date by which the risk will be treated as described
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        Updated On
                      </StyledTableCell>{' '}
                      <StyledTableCell align="right">
                        Date when the risk was added or last modified
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContentText>
          </div>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            sx={{ width: '90px', fontSize: '12px' }}
            onClick={onClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ReadmeFile
