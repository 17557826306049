import React from 'react'
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import { Button } from '@mui/material'
import { useSelector } from 'react-redux'
import _ from 'lodash'

const ExcelGenerator = (props) => {
  const { data, uniqueEmployees, revenuesOptions, countries, designations } = props
  const { userDetails } = useSelector((state) => state.user)
  const { csatoptionsdata } = useSelector((state) => state.csat)

  const generateExcel = async () => {
    const workbook = new ExcelJS.Workbook()
    let worksheet = workbook.addWorksheet('Sheet1')

    let worksheetMasterData = workbook.addWorksheet('MasterData')

    let columnNames=[]

    if (userDetails.role === 'Admin') {
      columnNames = [
       'Row Id', 'Vertical', 'Account', 'Project', 'Work Stream', 'Project Lead Email', 'CS Email', 'Internal Surveyee', 'Salutation',
  'Client First Name', 'Client Last Name', 'Client Email', 'Client Designation', 'Client Preferred Name','Client LinkedIn',  'Client Tenure', 
  'Client Base Location (Country)','Service Line', 'Revenue Bucket', 'Survey Type', 'Transformational Engaged','Mode Of Survey','Client Level', 'Country Code','Contact Number', 'Alternate Client Name', 'Alternate Client Email', 
   'Unique Id'
      ]
    }else {
      columnNames = [
       'Row Id', 'Vertical', 'Account', 'Project', 'Work Stream', 'Project Lead Email', 'CS Email', 'Internal Surveyee', 'Salutation',
  'Client First Name', 'Client Last Name', 'Client Email', 'Client Designation', 'Client Preferred Name','Client LinkedIn',  'Client Tenure', 
  'Client Base Location (Country)','Service Line', 'Revenue Bucket', 'Survey Type',  
  'Transformational Engaged','Mode Of Survey', 'Client Level','Country Code', 'Contact Number', 'Unique Id'
      ]
    }

    worksheetMasterData.getColumn(3).values = revenuesOptions
    worksheet.addRow(columnNames);
    columnNames.forEach((columnName, index) => {
      if (columnName !== 'Client LinkedIn' && columnName !== 'Work Stream' && columnName !== 'Project Lead Email'&& columnName !== 'Row Id'&& columnName !=='Alternate Client Name'&& columnName !=='Alternate Client Email'&& columnName !=='Country Code' && columnName !=='Contact Number') {
        const cell = worksheet.getCell(1, index + 1); 
        const richText = [{ text: columnName }, { font: { color: { argb: 'FF0000' } }, text: '*' }];
        cell.value = { richText };
      }
    });
    worksheet.columns.forEach((column) => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const length = cell.value ? String(cell.value).length : 0;
        if (length > maxLength) {
          maxLength = length;
        }
      });
      column.width = maxLength < 10 ? 10 : maxLength + 2;
    });
    let templateData=[]
    let colNames = []
    if (userDetails.role === 'Admin') {
     templateData = data.map((item, index) => ([
      index+1,
      item.vertical,
      item.account,
      item.project,
      item.workstream,
      '', '', '', '', '', '', '', '', '', '', '', '','','','','','','','','','','',
      item.unique_id,
    ]));
    colNames=[28]
  }else {
    templateData = data.map((item, index) => ([
      index+1,
      item.vertical,
      item.account,
      item.project,
      item.workstream,
      '', '', '', '', '', '', '', '', '', '', '', '','','','','','','','','',
      item.unique_id,
    ]));
    colNames=[26]
  }

    templateData.forEach(data => {
      worksheet.addRow(data);
    });
    
    const revenuFormula = `" ${revenuesOptions.join(',')}"`
    const transEngagedData = `" ${csatoptionsdata?.TransformationalEngaged.join(',')}"`
    const modeOfSurveyData = `" ${csatoptionsdata?.ModeOfSurvey.join(',')}"`
    const salutationData = `" ${csatoptionsdata?.Salutation.join(',')}"`
    const clientLevelData = `" ${csatoptionsdata?.ClientLevel.join(',')}"`

    for (let i = 2; i < data.length+2; i++) {
      worksheet.getCell(`S${i}`).dataValidation = {
        type: 'list',
        allowBlank: true,
        formulae: [revenuFormula],
      }
    }
    for (let i = 2; i < data.length+2; i++) {
      worksheet.getCell(`P${i}`).dataValidation = {
        type: 'list',
        allowBlank: true,
        formulae: [`"<3 months,3-6 months,6-12 months,>12 months"`],
      }
    }

    for (let i = 2; i < data.length+2; i++) {
      worksheet.getCell(`T${i}`).dataValidation = {
        type: 'list',
        allowBlank: true,
        formulae: ['"Generic,Solution Support"'],
      }
    }
 
    for (let i = 2; i < data.length+2; i++) {
      worksheet.getCell(`W${i}`).dataValidation = {
        type: 'list',
        allowBlank: true,
        formulae: [clientLevelData],
      }
    }
    for (let i = 2; i < data.length+2; i++) {
      worksheet.getCell(`I${i}`).dataValidation = {
        type: 'list',
        allowBlank: true,
        formulae: [salutationData],
      }
    }
    for (let i = 2; i < data.length+2; i++) {
      worksheet.getCell(`U${i}`).dataValidation = {
        type: 'list',
        allowBlank: true,
        formulae: [transEngagedData],
      }
    }
    for (let i = 2; i < data.length+2; i++) {
      worksheet.getCell(`V${i}`).dataValidation = {
        type: 'list',
        allowBlank: true,
        formulae: [modeOfSurveyData],
      }
    }
    for (let i = 2; i < data.length + 2; i++) {
      worksheet.getCell(`X${i}`).dataValidation = {
        type: 'custom',
        formulae: [`ISNUMBER(VALUE(X${i}))`],        
        allowBlank: true,
        showErrorMessage: true,
        errorStyle: 'stop',
        errorTitle: 'Invalid Country Code',
        error: 'Please enter a valid country code',
      };
    }

    for (let i = 2; i < data.length + 2; i++) {
      worksheet.getCell(`Y${i}`).dataValidation = {
        type: 'custom',
        formulae: [`ISNUMBER(VALUE(Y${i}))`],        
        allowBlank: true,
        showErrorMessage: true,
        errorStyle: 'stop',
        errorTitle: 'Invalid Contact Number',
        error: 'Please enter a valid contact number',
      };
    }
    
    
    
  //   for (let i = 2; i < data.length+2; i++) {
  //   worksheet.getCell(`X${i}`).note = {
  //     texts: [
  //       {
  //         font: { size: 9, name: 'Poppins', family: 2, scheme: 'minor' },
  //         text: 'Provide ',
  //       },
  //       {
  //         font: { size: 9, name: 'Poppins', scheme: 'minor' },
  //         text: 'Number ',
  //       },
  //       {
  //         font: { size: 9, name: 'Poppins', family: 2, scheme: 'minor' },
  //         text: 'With ',
  //       },
  //       {
  //         font: { size: 9, name: 'Poppins', scheme: 'minor' },
  //         text: 'Country ',
  //       },
  //       {
  //         font: { size: 9, name: 'Poppins', family: 2, scheme: 'minor' },
  //         text: 'Code ',
  //       },
  //     ],
  //     margins: {
  //       insetmode: 'custom',
  //       inset: [0.05, 0.05, 0.25, 0.25]      },
  //     editAs: 'twoCells',
  //   }
  // }
    worksheet.getColumn(colNames[0]).hidden = true;

    worksheetMasterData.state = 'veryHidden'
    const buffer = await workbook.xlsx.writeBuffer()
    saveAs(new Blob([buffer]), 'Csat Nominations - 2024 H2.xlsx')
  }

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        style={{ borderRadius: '15px' }}
        onClick={generateExcel}
      >
        Download Template
      </Button>
    </div>
  )
}

export default ExcelGenerator
